/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

$ms_red: #e81123;
$ms_orange: #d83b01;
$ms_yellow: #ffb900;
$ms_green: #107c10;
$ms_teal: #008272;
$ms_blue: #0078d7;
$ms_purple: #5c2e91;
$ms_white: #ffffff;
$ms_gray_1: #e6e6e6;
$ms_gray_2: #d2d2d2;
$ms_gray_3: #737373;
$ms_gray_4: #505050;
$ms_gray_5: #d6d6d6;
$ms_gray_6: #a0a0a0;
$ms_black: #000000;

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
.body-min-height {
  min-height: 460px;
}

body {
  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-clip: padding-box;
    background-color: $ms_gray_2;
    border: 3px solid transparent;
    min-height: 50px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }
}

.fade-data {
  opacity: 0.3;
}
.ag-theme-balham .ag-cell.yellow-bgs-select,
.ag-theme-balham .ag-cell.blue-bgs-select {
  border: black 1px solid;
}
.blue-bgs-select {
  border: 1px black solid;
  width: 100%;
  height: 100%;
  background: rgba(0, 120, 215, 0.15);
}
.yellow-bgs-select {
  border: 1px black solid;
  width: 100%;
  height: 100%;
  background: rgba(255, 193, 7, 0.15);
}
.yellow-bgs {
  width: 100%;
  height: 100%;
  background: rgba(255, 193, 7, 0.15);
}
.green-bgs {
  background: rgba(40, 167, 69, 0.15);
}
.red-bgs {
  background: rgba(220, 53, 69, 0.15);
}
.bold-font {
  font-weight: bold;
}
.ms_gray_2_bg {
  background-color: $ms_gray_2;
}

.red-bg {
  background-color: $ms_red;
}

.yellow-bg {
  background-color: $ms_yellow;
}

.green-bg {
  background-color: $ms_green;
}

.teal-bg {
  background-color: $ms_teal;
}

.white-text {
  color: $ms_white;
}

.black-text {
  color: $ms_black;
}

.red-text {
  color: $ms_red;
}

.orange-text {
  color: $ms_orange;
}

.purple-text {
  color: $ms-purple;
}

.green-text {
  color: $ms_green;
}

.yellow-text {
  color: $ms_yellow;
}

.gray-text {
  color: $ms_gray_3;
}

.teal-text {
  color: $ms_teal;
}

.blue-text {
  color: $ms_blue;
}

.green-btn {
  background-color: $ms_green;
  color: $ms_white;
}

.red-btn {
  background-color: $ms_red;
  color: $ms_white;
}

.yellow-btn {
  background-color: $ms_yellow;
  color: $ms_black;
}

.gray-btn {
  background-color: $ms_gray_3;
  color: $ms_white;
}

.teal-btn {
  background-color: $ms_teal;
  color: $ms_white;
}

.blue-btn {
  background-color: $ms_blue;
  color: $ms_white;
}

.default-btn {
  background-color: $ms_gray_1;
  color: $ms_black;
}

.btn-edit {
  background-color: #337ab7;
  color: $ms_white;
}

.btn-delete {
  background-color: #dc3545;
  color: $ms_white;
}

a:not([href]):not([tabindex]) {
  color: $ms_black;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.btn-secondary.access.btn {
  background-color: $ms_gray_5;
  color: $ms_white;
  font-weight: bold;
}

.green-bdg {
  background-color: $ms_green;
  color: $ms_white; // border-color: $ms_green;
  border: none;
}

.red-bdg {
  background-color: $ms_red;
  color: $ms_white; // border-color: $ms_red;
  border: none;
}

.yellow-bdg {
  background-color: $ms_yellow;
  color: $ms_black; // border-color: $ms_yellow;
  border: none;
}

.gray-bdg {
  background-color: $ms_gray_3;
  color: $ms_white; // border-color: $ms_gray_3;
  border: none;
}

.teal-bdg {
  background-color: $ms_teal;
  color: $ms_white; // border-color: $ms_teal;
  border: none;
}

.blue-bdg {
  background-color: $ms_blue;
  color: $ms_white; // border-color: $ms_blue;
  border: none;
}

.default-bdg {
  background-color: $ms_gray_1;
  color: $ms_black; // border-color: $ms_gray_1;
  border: none;
}

.p-l-r-64 {
  padding-left: 64px;
  padding-right: 64px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-t-b-l-24 {
  padding-top: 24px;
  padding-left: 20px;
  padding-bottom: 24px;
}
.p-l-64 {
  padding-left: 64px;
}
.p-l-r-33 {
  padding-left: 33px;
  padding-right: 33px;
}
.p-l-33 {
  padding-left: 33px;
}
.p-r-33 {
  padding-right: 33px;
}
.f-h-100 {
  height: 100%;
}

.m-l-0 {
  margin-left: 0;
}

.m-r-0 {
  margin-right: 0;
}

.m-r-10 {
  margin-right: 10px;
}

html,
body {
  height: 100%;
}

body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

//header css from third
@media (min-width: 1310px) {
  .container {
    max-width: 1310px;
  }
}

.row-outer {
  height: calc(100% - 326px);
  width: 100%;
}

@media (max-width: 768px) {
  .row-outer {
    height: 100%;
    width: 100%;
  }
  .column-wrap {
    height: 100%;
    padding-bottom: 60px;
  }
}

.tab {
  position: relative;
  margin-left: 0px;
  margin-bottom: 10px;
  border-left-style: solid;
  border-left-width: 5px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: $ms_gray_1;
  color: $ms_black;
  .alert-count {
    position: absolute;
    left: -38px;
    top: 9px;
  }
  .nav-link {
    color: $ms_black;
    padding: 0.5rem 0 0.5rem 1rem;
  }
}

.tab:hover {
  background-color: $ms_gray_1;
}

.content-container {
  padding: 10px 64px 100px 40px;
}

.flex-base {
  display: flex;
  .grid-flex-base-left {
    min-width: 212px;
  }
  .grid-flex-base-right {
    flex: 1;
    overflow: hidden;
  }
  .flex-base-left {
    width: 212px;
  }
  .flex-base-right {
    flex: 1;
  }
}

.flex-base-column-b {
  flex: 1;
  overflow: hidden;
}

.flex-base-left-review {
  width: 274px;
}

.flex-base-column {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1360px) {
  .flex-base-nav {
    flex-wrap: wrap;
    flex-direction: unset;
  }
}

#toast-container > .toast-warning,
.toast-info,
.toast-success,
.toast-error {
  background-color: $ms_gray_2 !important;
  color: $ms_black !important;
}

.nav {
  .item {
    width: 100%; // fix side-bar overflow style
  }
}

.popover {
  max-width: 1000px;
  width: 500px;
}
